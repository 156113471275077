// ************************************************************
//JS: lovell-basket-page.js
// Lovell Rugby website functions specific to basket page and components
// (c) 2015 Lovell Rugby Limited

var voucherCodeVal;

$(function() {
  // change position of the price in basket history items depending on screen size
  if (getDeviceType() == "mobile" || getDeviceType() == "tablet") {
    $(".basket-price-history").each(function() {
      $(this)
        .prev(".basket-data")
        .children(".basket-data-inner")
        .append($(this));
    });
  }

  // copy the 'spend another...' message to the suggested items block it they're both there
  if (
    $(".basket-spend-more-message").length &&
    $(".suggested-items__title").length
  ) {
    $(".suggested-items__title").after(
      "<p>" + $(".basket-spend-more-message").html() + "</p>"
    );
  }

  // save gift message options to cookies on basket page when moving to checkout
  $(".btn-basket-checkout").on('click', function(event) {
    if (document.cookie.match(/\baddExchangeOrder=\d+\b/)) {
      try {
        var exchangeInOrder = $("#exchangeinorder").val();
        window.opener.postMessage(exchangeInOrder, "*");
        deleteCookie('addExchangeOrder');
        self.close();
      } catch (e) {
        // Bad things.
        throw new Error("Couldn't reach return func on parent window");
      }
      return;
    }
  });

  // if the basket has in stock items AND pre-order items, show the headers
  if ($(".header-pre-order").length && $(".header-in-stock").length) {
    $(".header-in-stock").show();
    $(".header-pre-order").show();
  }

  // display no of items badge on baskets
  displayNoOfItemsBadge();

  // show/hide voucher code input
  $(document).on("click", ".basket-voucher-header", function(e) {
    $(this)
      .find("img")
      .toggle();
    $(this)
      .toggleClass("open")
      .closest(".basket-voucher")
      .find(".basket-voucher-content")
      .slideToggle("fast");
  });

  // show/hide order is a gift code input
  $(document).on("click", ".basket-footer-item-header", function(e) {
    $(this)
      .find("img")
      .toggle();
    $(this)
      .toggleClass("open")
      .closest(".basket-footer-item")
      .find(".basket-footer-item-content")
      .slideToggle("fast");
    $(window).resize();
  });

  // set the global var to the initial val of the input (the default help message)
  voucherCodeVal = $(".mediacode").val();
  // voucher input - remove default text on focus
  $(".mediacode").focus(function() {
    $(this)
      .val("")
      .css("color", "#333");
  });
  // voucher input - restore the input help text on blur if nothing entered
  $(".mediacode").focusout(function() {
    if ($(this).val() == "") {
      $(this)
        .val(voucherCodeVal)
        .css("color", "#999");
    }
  });

  $(document).on("click", ".toggleBasketUpdateBox", function(e) {
    var $target = $(e.target);
    if ($target.is("img")) {
      // Correct event target to 'toggleBasketUpdateBox' div if user clicks on plus/minus image within button.
      $target = $target.parent();
    }
    toggleBasketUpdateBox($target);
  });

  // remove the border from the basket totals section if there are no delivery options
  if ($(".basket-footer-items").length) {
    if ($(".delivery-options").is(":empty")) {
      $(".basket-summary").css("border-top", "none");
    }
  }

  // activate the delivery option button click when the delivery date is clicked
  $(".del-date").on('click', function() {
    $(this)
      .prev(".btn-del-option")
      .click();
  });

  // Handle slide toggle of delivery options
  // Hide bottom border of selected delivery option when the menu is open
  // and reactivate after the closing animation is complete
  $(document).on("click", ".selected-delivery-option", function () {
    var el = $(this);
    var hiddenBorder = false;
    if (!el.hasClass("active")) {
      el.addClass("active");
      hiddenBorder = true;
    }
    $(".delivery-options-select").slideToggle("fast", function () {
      if (!hiddenBorder) {
        el.removeClass("active");
      }
    });
  });

  // move basket notes ('Order before 3pm for express delivery' etc) to before the basket title
  $(".basket-note-order-before").insertBefore("h1.basket-title");

  basketFormatUpdate();

  // hide multiple 'order before' messages
  if ($(".basket-note.basket-note-order-before").length > 1) {
    $(".basket-note.basket-note-order-before").not(":first-child").remove();
  }

  // quantity select
  $(document).on("click", ".qty-select", function () {
    $(this).find(".quantity-dropdown").toggleClass("active");
  });
  $(document).on("click", ".quantity-dropdown li", function () {
    var currentQty = parseInt($("#ba_Quantity").val());
    var newQty = parseInt($(this).text());
    forCart("qty", newQty - currentQty);
  });

  $('.selected-delivery-option .delivery-option').each(
    function (i, e) {
      $(this).removeAttr('onclick');
    }
  );

}); // doc ready

$(document).on("basketUpdate", function () {
  basketFormatUpdate();
});

function toggleBasketUpdateBox($target) {
  if (
    $target
      .closest(".basket-item")
      .find(".basketEditItemBlock")
      .is(":hidden")
  ) {
    $(".basketEditItemBlock").css("display", "none");
    $(".basketEditItemBlock").html(""); // We don't want multiple 'edit item' blocks open, so close any already-open ones here.
    $(".toggleBasketUpdateBox")
      .find(".plus")
      .show();
    $(".toggleBasketUpdateBox")
      .find(".minus")
      .hide();
    $(".toggleBasketUpdateBox").addClass("active");
    addToCartSize = ""; // Reset 'addToCartSize' var, used in forCart() function.
    $(".editItemBlock_" + $target.attr("data-toggle-block")).css(
      "display",
      "block"
    );
    showAnimatedSpinner(
      ".editItemBlock_" + $target.attr("data-toggle-block"),
      "DARK"
    );
    var itemData = $target.attr("data-item-data");
    $.ajax({
      url: skinJS_SiteURL + "/cgi-bin/ajax-loadbasketupdateitemform.cgi",
      data: { itemData: itemData },
      success: function(data) {
        $(".editItemBlock_" + $target.attr("data-toggle-block")).html(data);
        var size = itemData.split("~")[1];
        forCart("size", size, 1);
        if (typeof $("select").selectOrDie == "function") {
          $("select").selectOrDie();
        }
        if ($(".variants-list .orderButtonSelected").length) {
          $(".variants-list")
            .find(".select-variants-header")
            .text($(".variants-list .orderButtonSelected span").text());
        }
        // create drop down for quantity in basket item edit
        var numberList =
          '<ul class="quantity-dropdown"><li>1</li><li>2</li><li>3</li><li>4</li><li>5</li><li>6</li><li>7</li><li>8</li><li>9</li><li>10</li></ul>';
        $(".basketEditItemBlock .qty-select").append(numberList);
        LovellPersn.init();
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {}
    });
  } else {
    $(".editItemBlock_" + $target.attr("data-toggle-block")).html("");
    $(".basketEditItemBlock").css("display", "none");
    $(".toggleBasketUpdateBox").removeClass("active");
  }
  $target.find(".plus").toggle();
  $target.find(".minus").toggle();
}

function displayNoOfItemsBadge(noOfItemsinBasket) {
  // see if there's a value in 'total-items-updated' div - it is updated by the basketupdate ajax script and therefore will hold the most up to date value
  if (noOfItemsinBasket) {
    $("#basket-total-items").text(noOfItemsinBasket);
  } else {
    if ($("#basket-total-items").length) {
      noOfItemsinBasket = $("#basket-total-items").text();
    }
  }

  if (parseInt(noOfItemsinBasket) > 0) {
    // add badge to the mobile/tablet basket button
    $("#btn-basket").append(
      '<div class="items-badge">' + noOfItemsinBasket + "</div>"
    );
    $("#btn-checkout").css("background-color", "#1b8a0d");
    $("#basket-total-items").show();
  } else {
    $("#basket-total-items").hide();
  }
}

function deleteCompactBasketItem(valueID) {
  if (document.getElementById(valueID)) {
    document.getElementById(valueID).value = 0;
    document.forms["updateCompactBasketForm"].submit();
  }
}

// AJAX - Update basket on server
function updateBasket(productId, size, quantity, el) {
  if (!productId) {productId = getVal("ba_ProductID");}
  if (!size) { size = getVal("ba_Size"); }
  var productName = getVal("ba_ProductName") || '';
  var givenPrice = "";
  if (document.getElementById("itemGivenPrice")) {
    givenPrice = getVal("itemGivenPrice");
  }
  var isSDPersn = $( "input[type=hidden][name=isSDPersn]" ).val();
  var personalisation = isSDPersn ? LovellPersn.getServiceValuesString() : '';
  var sizeBlocks = $(".size-block").length;
  if (!quantity) { quantity = $(".quantity-selector").length ? 1 : getVal("ba_Quantity"); }

  if (productId && quantity && (size || !sizeBlocks)) {
    if (document.cookie.match(/\baddItemToExistingOrder=\d+\b/)) {
      $("#addThisProductToBasket").append('<p>Added product: ' + productId + ' (' + size + ') x' + quantity +'</p>');
      try {
        window.opener.postMessage(
          {
            ProductID: productId,
            Quantity: quantity,
            Size: size,
            Personalisation: personalisation,
            Price: givenPrice
          },
          "*"
        );
      } catch (e) {
        // Bad things.
        throw new Error("Couldn't reach return func on parent window");
      }
      return;
    }
    basketUpdate(
      productId +
        "~" +
        quantity +
        "~" +
        size +
        "~" +
        personalisation +
        "~" +
        givenPrice,
      el
    );
    $("#basketInfo").html("");
    updateCompactBasket(); // Update compact basket

    if (quantity % 2 !== 0 && !$(".add-another").is(":visible")) {
      $(".add-another").show();
    } else {
      $(".add-another").hide();
    }

    // analytics tracking
    analyticsAddRemoveFromCart(
      {
        id: productId,
        quantity: quantity,
        price: $(".price-main")
          .first()
          .text()
          .replace(/[^\d\.]/g, ""),
        variant: size,
        productName: productName
      },
      "add"
    );
  } else if (!size) {
    $("#addThisProductToBasket .noSizeSelectedWarning").fadeIn("fast");
  }
}

// Slide into view a message indicating success / failure of
// a basket update. If there is a timeout available on the element
// toggle the message after the specified period.
var displayBasketMessage = (function () {
  return function displayBasketMessage($el) {
    $el.addClass('active');
    setTimeout(function () {
      $el.removeClass('active');
    }, 4000);
  };
})();

// Show basket success message - it's contents are pre-rendered on the server
function displayBasketSuccess() {
  var $el = $("#add-to-basket-success");
  displayBasketMessage($el);
}

// Show basket warning message - the message is returned from an ajax request
function displayBasketWarning(html) {
  var $el = $("#add-to-basket-warning");
  var $textEl = $("#add-to-basket-warning .text");
  $textEl.html(html);
  displayBasketMessage($el);
}

// After the user has clicked the 'Add to Basket' button, disable the button
// and show a spinner until the ajax request completes (or errors!)
function setAddToCartLoading(el) {
  var $el = $("#addToCartLink");
  if ($(el).length) { // When adding items to the basket from the wishlist page, we want to show loading indicator on the relevant wishlist 'add to basket' button rather than the default #addToCartLink button
    $el = $(el);
  }
  
  var text = "Adding to basket...";
  var loader = '<span class="loader"></span>';
  $el.data({ loading: true, previousText: $el.text() }).html(text + loader);
}

// Enable the 'Add to basket' button when the ajax finishes loading
function setAddToCartLoaded(el) {
  var $el = $("#addToCartLink");
  if ($(el).length) { // When adding items to the basket from the wishlist page, we want to show loading indicator on the relevant wishlist 'add to basket' button rather than the default #addToCartLink button
    $el = $(el);
  }
  var text = $el.data("previousText");
  $el
    .data({ loading: false, previousText: null })
    .html(text)
    .removeClass("active");
}

// Hide basket messages if they're visible on the page
function hideBasketMessages() {
  var $successEl = $("#add-to-basket-success");
  var $warningEl = $("#add-to-basket-warning");

  $successEl.removeClass('active');
  $warningEl.removeClass('active');
}


function basketUpdate() {
  // Update the basket via AJAX
  var args = arguments;
  hideBasketMessages();
  setAddToCartLoading(args[1]);
  $.ajax({
    url: skinJS_SiteURL + "/cgi-bin/basketupdate.cgi",
    data: {
      basketData: args[0],
      isPhoneOrder: $(".isPhoneOrder").val(),
      isTradeAccount: $(".isTradeAccount").val()
    },
    success: function(data) {
      $("#basketSummary").html(data);
      displayNoOfItemsBadge();
      setAddToCartLoaded(args[1]);
      var $warningEl = $(".basketUpdate .warning");
      if ($warningEl.length) {
        displayBasketWarning($warningEl.html());
      } else {
        displayBasketSuccess();
      }
    },
    error: function() {
      setAddToCartLoaded(args[1]);
      displayBasketWarning("Failed to add the item to the basket");
    }
  });
}

var ba_ProductID = "";
var ba_oldSize = "";
var ba_oldColour = "";
var ba_oldPersn = "";
var ba_oldPrice = "";

function updateBasketItem(productID, oldSize, oldColour, oldPersn, oldPrice) {
  // Update a single item in basket, from 'edit item details' dropdown box.
  var newSize = $("#ba_Size").val();
  var newQuantity = $("#ba_Quantity").val();
  var newPersn = LovellPersn.getServiceValuesString();

  var orderLimit = $("#orderLimit").val();

  if (!productID) {
    productID = ba_ProductID;
  }
  if (!oldSize) {
    oldSize = ba_oldSize;
  }
  if (!oldColour) {
    oldColour = ba_oldColour;
  }
  if (!oldPersn) {
    oldPersn = ba_oldPersn;
  }
  if (!oldPrice) {
    oldPrice = ba_oldPrice;
  }
  if (parseInt(orderLimit) && newQuantity > orderLimit) {
    // dpxxx: show some kind of message to the user when resetting selected quantity like this
    newQuantity = orderLimit;
  }
  // Get new product data from ba_Size, ba_Quantity and ba_Personalisation. Send AJAX request to update basket data and completely reload basket.
  // Must send sitecode for LFC-R, so persn costs are correct locally
  var updateData =
    productID +
    "~" +
    oldSize +
    "~" +
    oldColour +
    "~" +
    oldPersn +
    "~" +
    oldPrice +
    "~" +
    newSize +
    "~" +
    newQuantity +
    "~" +
    newPersn +
    "~" +
    skinJS_SiteCode;
  $.ajax({
    url: skinJS_SiteURL + "/cgi-bin/ajax-updateiteminbasket.cgi",
    data: { basketData: updateData },
    success: function(data) {
      var basketData = data.split("~~||~~");
      $(".contentBox").html(basketData[0]);
      $("#basketSummary").html(basketData[1]);
      if (typeof $(".slider select").selectOrDie == "function") {
        $(".slider select").selectOrDie();
      }
      hideSliderPlaceholders();
      applySliderButtonCSS();
      updateCompactBasket();
      basketConfig();
      $(document).trigger(new $.Event("basketUpdate"));
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {}
  });
}

function prepareBasketDisplay() {
  // initialise vertical slider products list in mini basket
  if (typeof $("#mini-basket-panel .media-list").imagesLoaded == "function") {
    $("#mini-basket-panel .media-list").imagesLoaded().always(function() {
      var ht = 0;
      // set the height of the list wrapper to the height of the first 2 items
      $("#mini-basket-panel .media-list .basket-item").each(function(i) {
        if (i < 2) {
          ht += $(this).outerHeight(true);
        }
      });
      $("#mini-basket-panel .media-list").css("height", ht);
      // set the arpp
    });
  }
}

function updateCompactBasket(reloadPageOnComplete) {
  "use strict";
  // Updates content of compact basket 'pop-up'
  // The content of this div is updated by the response to the AJAX function basketUpdate
  var newBasket = document.getElementById("newBasket"),
    bi = document.getElementById("mini-basket-panel");
  if (newBasket && newBasket.innerHTML) {
    if (
      newBasket.innerHTML === "Updated currency" ||
      newBasket.innerHTML === "Updated delivery address"
    ) {
      // We are updating the currency and need to reload the page
      setTimeout("window.location.reload()", 250);
    } else {
      // copy the contents of newbasket to the hidden modal div ready for display
      if (bi) {
        bi.innerHTML =
          '<div class="basketInfo">' + newBasket.innerHTML + "</div>";
      }
      prepareBasketDisplay();
      newBasket.innerHTML = ""; // Blank this so we can check when we've got new content
      if (reloadPageOnComplete == 1) {
        setTimeout("window.location.reload()", 250);
      }
    }
  } else {
    if (reloadPageOnComplete) {
      setTimeout("updateCompactBasket(1)", 100);
    } else {
      setTimeout("updateCompactBasket()", 100);
    }
    if (bi) {
      bi.innerHTML = "Updating...";
    }
  }
}

// Code for multiple free item selector in basket
function freeItemClick(obj) {
  // Get some data from the clicked item
  var clickedItem = $(obj);
  var pid = clickedItem.data("product-id");
  var sizes = (clickedItem.data("product-sizes") + "").split(",");
  var name = clickedItem.data("product-name");
  var offerID = clickedItem.data("offer-id");

  // Fill some data in on the popup, and set the hidden input with the clicked productid
  $("#freeitemproductid" + offerID).val(pid);
  $("#modal-free-item-product-image").attr({
    src: skinJS_SiteURL + "/" + clickedItem.data("product-image"),
    alt: name,
    width: clickedItem.data("product-image-width"),
    height: clickedItem.data("product-image-height")
  });
  $("#modal-free-item-product-name").text(name);
  $("#modal-free-item-product-desc").html(clickedItem.data("product-desc"));
  $("#modal-free-item-variation-name").text(clickedItem.data("variation-name"));
  $("#modal-free-item-rrp").text(clickedItem.data("rrp"));
  $("#modal-free-item-price").text(clickedItem.data("price"));

  // Create the size choice buttons
  var sizeChoices = "";
  if (clickedItem.data("product-sizes")) {
    for (var i = 0; i < sizes.length; i++) {
      sizeChoices +=
        '<div class="orderButtonDiv"><a class="orderButton size" data-offer-id=' +
        offerID +
        "><span>" +
        sizes[i] +
        "</span></a></div>";
    }
  }

  // If there's only one size, select it by default
  if (sizes.length == 1) {
    $("#freeitemsize" + offerID).val(sizes);
    sizeChoices = sizeChoices.replace(/orderButton\s/, "orderButtonSelected ");
  } else {
    $("#freeitemsize" + offerID).val("");
  }

  // Put html in modal, add click events
  $("#modal-free-item-variant-select .size-grid").html(sizeChoices);
  $("#modal-free-item-variant-select .size-grid .size").click(
    freeItemSizeClick
  );

  // Show modal
  $("#modal-free-item-variant-select").modal();
}

function freeItemSizeClick(e) {
  // Remove selected class from all size buttons, then re-add to the clicked item
  $("#modal-free-item-variant-select .size-grid .size")
    .removeClass("orderButtonSelected")
    .addClass("orderButton");
  $(e.currentTarget).addClass("orderButtonSelected");
  var offerID = $(e.currentTarget).data("offer-id");
  $("#freeitemsize" + offerID).val($(e.currentTarget).text());
}

function freeItemConfirm(offerID) {
  // Check a productid and size are set, then submit form
  if (
    !$("#freeitemsize" + offerID).val().length ||
    !$("#freeitemproductid" + offerID).val().length
  ) {
    window.event.preventDefault();
    return false;
  }
}

function toggleGroupSubOrders(visitCode, setGrouping) {
  // Set hidden input value when toggle 'group sub orders' checkbox on or off
  doAjaxAction(
    {
      action: "TOGGLE_GROUP_SUB_ORDERS",
      groupSubOrders: setGrouping,
      visitCode: visitCode
    },
    updateBasketItem()
  );
}

function basketConfig() {

  // Shift stock warning text
  $(".basket-stock-warning").each(function() {
    $(this)
      .closest(".basket-data-inner")
      .find(".basket-item-title")
      .append(this);
  });
  $("#basket-tables .delivery-options").appendTo(
    ".basket-footer-item.basket-carriage"
  );
  $("#basket-tables .delivery-options .basket-note").appendTo(
    ".basket-carriage"
  );
  // delivery options

  $("#basket-tables .delivery-options").prepend(
    '<div class="selected-delivery-option"></div><div class="delivery-options-select"></div>'
  );
  $("#basket-tables .delivery-options .delivery-option").appendTo(
    ".delivery-options-select"
  );
  $("#basket-tables .delivery-options .delivery-option .colour-selected")
    .parent()
    .appendTo(".selected-delivery-option");
  $("#basket-tables .basket-footer-item.basket-giftorder").insertAfter(
    ".basket-footer-item.basket-carriage"
  );
  $("#basket-tables .basket-footer-item.basket-voucher").insertAfter(
    ".basket-footer-item.basket-carriage"
  );

  $("#basket-tables .basket-footer-item.basket-po-reference").insertAfter(
    ".basket-footer-item.basket-carriage"
  );

  $("#basket-tables .basket-footer-item.basket-associate-club").insertAfter(
    ".basket-footer-item.basket-giftorder"
  );

  $("#basket-tables .selected-delivery-option .delivery-option").removeAttr(
    "onclick"
  );
  $("#basket-tables .basket-footer-item.basket-carriage .label")
    .text("Delivery")
    .show();
  $("#basket-tables .basket-note").prependTo("#basket-table");
  if (getDeviceType() == "desktop" || getDeviceType() == "wide") {
    $("#basket-tables .basket-column--2").insertAfter("#basket-table");
    $("#basket-tables .basket-title").prependTo("#basket-table");
    $("#checkout-side-basket .basket-title").prependTo("#basket-table");
  }
  // multi order basket
  if ($(".baskettables.multi-order").length) {
    $("#basket-tables-inner").prepend('<div class="basket-main"></div>');
    $("#basket-tables-inner > div")
      .not(".suggested-items")
      .not(".stock-message")
      .not(".mciMessage")
      .appendTo(".basket-main");
    $(".basket-column--2").insertAfter(".basket-main");
    $(".stock-message").insertBefore(".basket-main");
    $(".mciMessage").insertBefore(".basket-main");
    $('<div class="basket-footer-item basket-carriage"></div>').insertBefore(
      ".basket-footer-item.basket-total"
    );
    $(".baskettables.multi-order .delivery-options").appendTo(
      ".basket-column--2 .basket-carriage"
    );
    $(".baskettables .delivery-options").prepend(
      '<div class="selected-delivery-option"></div><div class="delivery-options-select"></div>'
    );
    $(".baskettables .delivery-options .delivery-option").appendTo(
      ".delivery-options-select"
    );
    $(".baskettables .delivery-options .delivery-option .colour-selected")
      .parent()
      .appendTo(".selected-delivery-option");
    $(".baskettables .selected-delivery-option .btn-del-option").removeAttr(
      "onclick"
    );
    $(".baskettables .basket-footer-item.basket-giftorder").insertAfter(
      ".basket-footer-item.basket-carriage"
    );
    $(".baskettables .basket-footer-item.basket-voucher").insertAfter(
      ".basket-footer-item.basket-carriage"
    );
    $("#basket-tables .basket-footer-item.basket-po-reference").insertAfter(
      ".basket-footer-item.basket-carriage"
    );
    $("#basket-tables .basket-footer-item.basket-carriage .label")
      .text("Delivery")
      .show();
    if (getDeviceType() == "mobile" || getDeviceType() == "tablet") {
      $("#checkout-side-basket .basket-column--2").appendTo(
        ".basket-footer-items-inner"
      );
    }
  }

  var contents = $('.basket-column .delivery-options-select').text();
  if (!contents){
    $('.basket-column .basket-carriage').addClass('one-option');
  }

  // free item in basket
  $(".offer-chooser")
    .not(".free-item-chooser")
    .prependTo("#basket-table");

  // change size select boxes to select box
  $(".offer-chooser__options").append('<select name="offer-chooser__size-select" class="offer-chooser__size-select"><option value="">Select size</option></select>');
  $(".offer-chooser__options-buttons .btn-free-item-variant")
    .not(".offer-chooser__no-thanks")
    .each(function() {
      var cleanClass = makeValidId($(this).val());
      $(this).addClass("offer-chooser__size-" + cleanClass);
      $(".offer-chooser__size-select").append(
        '<option value="offer-chooser__size-' +
          cleanClass +
          '">' +
          $(this).val() +
          "</option>"
      );
    });
  $(".offer-chooser__size-select").selectOrDie();
  // no thanks button
  $(".offer-chooser__options").append('<a class="offer-chooser__no-thanks-link">No thanks</a>');
  $(document).on("change", ".offer-chooser__size-select", function() {
    if ($(this).val()) {
      var sizeClass = makeValidId($(this).val());
      $("input." + sizeClass).click();
    }
  });
  $(".offer-chooser__body-options-wrapper").each(function() {
    var $el = $(this);
    var $priceEl = $el.find(".offer-chooser__price");
    var $optionsEl = $el.find(".offer-chooser__options");
    var $targetEl = $el.find(".offer-chooser__body");
    $priceEl.insertBefore($targetEl);
    $optionsEl.insertAfter($targetEl);
  });
  $(document).on("click", ".offer-chooser__no-thanks-link", function() {
    $(this).closest(".offer-chooser__options").find(".offer-chooser__no-thanks").click();
  });

  // Move basket title inside the list of basket items
  if (getDeviceType() === "desktop" || getDeviceType() === "wide") {
    if ($(".baskettables.multi-order").length) {
      $(".basket-title")
        .prependTo(".basket-main")
        .first();
    } else {
      $(".basket-title").prependTo(".basketTable");
    }
  }

  $('.basket-note-order-before').insertBefore('h1.basket-title');

  // Take a list of media code elements and group them by message preserving order
  function fetchMediaCodeMessages($elements) {
    var messages = [];
    $elements.each(function() {
      var message = $(this).text();
      var existing;
      messages.forEach(function(data) {
        if (data.message === message) {
          existing = data;
        }
      });
      if (!existing) {
        messages.push({
          message: message,
          elements: [this]
        });
      } else {
        existing.elements.push(this);
      }
    });
    return messages;
  }

  // Fetch all media code message codes and reverse to prepend back into the DOM at the top of basket display
  var messages = fetchMediaCodeMessages(
    $(".baskettables .mciMessage, .baskettables .carriageMessage")
  ).reverse();
  messages.forEach(function(data) {
    data.elements.forEach(function(element, i) {
      if (i === 0) {
        $(element).prependTo(".baskettables");
      } else {
        $(element).remove();
      }
    });
  });
}

function basketFormatUpdate() {
  // move 'expected to you' date on split order basket into section header
  if ($(".header-pre-order").length) {
    $(".header-pre-order").each(function () {
      var tableid = $(this).closest(".basketTable").data("order");

      $(".basket-expected[data-order=" + tableid + "]").appendTo(this);
    });
  }

  $(".preorder-available").each(function () {
    var preorderid = $(this).data("order");

    $(this).prependTo(
      $(".preorder-available")
        .closest(".basketTable[data-order=" + preorderid + "]")
        .find(".basket-data:first")
    );
  });

  if ($(".header-in-stock").length) {
    $(".header-in-stock").each(function () {
      var tableid = $(this).closest(".basketTable").data("order");

      $(".basket-expected[data-order=" + tableid + "]").appendTo(this);
    });
  }

  $(".product-status-wrapper").each(function () {
    $(this).insertBefore(
      $(this).closest(".basketTable").find(".basket-item:first")
    );
  });

  // add a read more link to split order message
  $(".stock-message-wrapper .read-more").on('click', function () {
    $(this).hide().next(".stock-message-small").fadeIn();
  });

  // move split order message to before the basket title
  $(".stock-message-wrapper").insertBefore("h1.basket-title");
  $(".mciMessage").insertBefore("h1.basket-title");
}